<template>
  <div>
    <ul v-if="!isRegistrationWizard" class="biz-breadcrumb">
      <li><router-link to="/supplier/manage">{{$t('SYSTEM_MANAGEMENT.TITLE')}}</router-link></li>
      <li><router-link to="/supplier/manage/company-info">{{$t('COMPANY_INFO.TITLE')}}</router-link></li>
      <li><a>{{$t('COMMON.EDIT')}}</a></li>
    </ul>
    <div class="page">
      <div class="page-title">
        <h1>{{$t('COMPANY_INFO.TITLE')}}</h1>
        <p>{{$t('COMPANY_INFO.DESCRIPTION')}}</p>
      </div>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <b-form @submit.prevent="handleSubmit(onSave)">
          <BasicInfo v-model="form"/>
          <div class="text-center mt-5">
            <j-button variant="primary" size="lg" buttonType="submit">
              {{isRegistrationWizard? $t('COMMON.NEXT'):$t('COMMON.SAVE')}}
            </j-button>
            <div class="mt-4" v-if="!isRegistrationWizard">
              <j-button variant="light" to="/supplier/manage/company-info">
                <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                {{$t('COMMON.GO_BACK')}}
              </j-button>
            </div>
          </div>
        </b-form>
      </ValidationObserver> 
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
<script>
  
  import CompanyInfoModel from '@/model/CompanyInfo';
  
  import BasicInfo from '@/components/register/BasicInfo.vue';

  export default {
    props: ["isRegistrationWizard", "notSave", "formData"],
    components: {
      BasicInfo
     },
    data(){
      return {
        form: {},
        tsr: {}
      }
    },
    mounted(){
      Promise.all([
        CompanyInfoModel.getCompanyBasicInfo(this.$store.state.user.companyCode),
        CompanyInfoModel.getTsrStatus(this.$store.state.user.companyCode)
      ]).then((results) => {
        this.form = results[0].data;
        if(this.formData){
          if(Object.keys(this.formData.company).length > 0)
            this.form   = this.formData.company.basic;
        }
        let tsrStatus = results[1].data;
        if(!tsrStatus.isCorrect){
          this.tsr = JSON.parse(tsrStatus.failedReason);
        }
        
        this.$refs.form.reset();
      }).catch(reason => {
        this.$errorMessage(undefined, {reason});
      });
    },

    methods: {
      async onSave(){
        if(this.notSave){
          this.$emit('onSave', {
            company: {
              basic: this.form
            }
          });
        }else{
          let popupRes = true;
          if(this.$store.state.user && this.$store.state.user.status.registrationCompleted){
            popupRes = await this.$bvModal.msgBoxConfirm(this.$t('COMPANY_INFO.CONFIRM_BOX'), {
              okTitle: this.$t('COMMON.UPDATE'),
              cancelTitle: this.$t('COMMON.CANCEL')
            })
          }
          if(popupRes){
            // VinhVN fix bug BUG23023-1959
            if (this.form.applicationType == '2') {
              this.form.corporationNumber = null;
            }
            CompanyInfoModel.saveCompanyBasicInfo(
            this.$store.state.user.companyCode, 
            this.form).then(()=>{
              if(this.$store.state.user && this.$store.state.user.status.registrationCompleted){
                this.$successMessage(this.$t('COMPANY_INFO.SUCCESS_MESSAGE'));
              }else{
                this.$successMessage(this.$t('COMPANY_INFO.SUCCESS_MESSAGE'));
              }
              this.$router.push('/supplier/manage/company-info');
            }).catch(reason => {
              this.$errorMessage(undefined, {reason});
            });
          }
        }
      }
    }
  }
</script>